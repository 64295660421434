<template>
    <b-row>
      <b-col sm="12">
        <div v-if="loading">
              <Loading />
            </div>
            <b-overlay v-else>
              <div class="p-3">
                <b-row>
                  <b-col sm="12">
                    <div>
                      <div class="stepper-wrapper">
                        <div :class="item.value <= appDetail.status ? `stepper-item completed` : `stepper-item`"
                          v-for="(item, index) in stepList" :key="index">
                          <div class="step-counter">
                            <i v-if="item.value <= appDetail.status" class="ri-check-fill"></i>
                            <span v-else>{{ $n(index+1) }}</span>
                          </div>
                          <div class="step-name">{{ item.text }}</div>
                        </div>
                      </div>
                      <!-- Application view start -->
                      <div class="application-form-wrapper application-form-view-wrapper mt-5">
                        <div class="application-form-wrapper">
                        <div class="application-itmes">
                            <b-row>
                                <b-col sm="12">
                                    <div class="group-form-card">
                                        <b-card>
                                                <b-row>
                                                    <b-col class="ml-5 pl-5 mr-3 mt-5">
                                                        <div class="mb-5 section-head">
                                                            <span>{{ checkBn ? 'বরাবর' : 'To' }}</span><br>
                                                            <span>{{ checkBn ? 'সিনিয়র সচিব/সচিব' : 'Senior Secretary/Secretary' }}</span><br>
                                                            <span>{{ checkBn ? 'বাণিজ্য মন্ত্রণালয়' : 'Ministry of Commerce' }}</span><br>
                                                            <span>{{ checkBn ? 'বাংলাদেশ সচিবালয়, ঢাকা।' : 'Bangladesh Secretariat, Dhaka.' }}</span>
                                                        </div>
                                                        <div class="section-subject">
                                                            <b-row>
                                                                <div class="col-sm-1 font-weight-bold" style="max-width: 10.333333% !important;">{{ checkBn ? 'বিষয়' : 'Subject' }}: </div>
                                                                <div class="col-sm-5">
                                                                    <p style="border-bottom: 1px dotted">{{ appDetail.subject }}</p>
                                                                </div>
                                                                <div style="max-width: 49% !important;" class="col-sm-6">{{ checkBn ? 'শীর্ষক স্থানীয়/আন্তর্জাতিক বাণিজ্য মেলা আয়োজনের অনুমতি প্রদান।' : 'grant of permission for local/international trade fairs.'}} </div>
                                                            </b-row>
                                                        </div>
                                                        <div class="mb-2 mt-4">{{ checkBn ? 'মহোদয়' : 'Dear Sir' }},</div>
                                                        <div class="section-body mt-2">
                                                            <b-row>
                                                                <div :class="checkBn ? 'col-sm-3' : 'col-sm-3'">{{ checkBn ? 'সবিনয় নিবেদন এই যে, আমি' : 'I am interested at location' }} </div>
                                                                <div :class="checkBn ? 'col-sm-4' : 'col-sm-4'">
                                                                    <p style="border-bottom: 1px dotted">{{ appDetail.applicant_name }}</p>
                                                                </div>
                                                                <div :class="checkBn ? 'col-sm-5' : 'col-sm-5'">
                                                                  <p style="border-bottom: 1px dotted">{{ appDetail.place }}</p>
                                                                </div>
                                                            </b-row>
                                                            <b-row>
                                                                <div :class="checkBn ? 'col-sm-1' : 'col-sm-5'">{{ checkBn ? 'স্থানে' : 'for organizing Local/International Trade Fair  from' }} </div>
                                                                <div :class="checkBn ? 'col-sm-4' : 'col-sm-3'">
                                                                  <p style="border-bottom: 1px dotted">{{ appDetail.from_date | dateFormat }}</p>
                                                                </div>
                                                                <div :class="checkBn ? 'col-sm-2' : 'col-sm-1'">{{ checkBn ? 'তারিখ থেকে' : 'to' }} </div>
                                                                <div :class="checkBn ? 'col-sm-4' : 'col-sm-3'">
                                                                  <p style="border-bottom: 1px dotted">{{ appDetail.to_date | dateFormat }}</p>
                                                                </div>
                                                                <div v-if="checkBn" class="col-sm-1"> {{ 'পর্যন্ত' }}</div>
                                                            </b-row>
                                                            <b-row>
                                                                <div v-if="!checkBn" class="col-sm-1"> {{ 'name' }}</div>
                                                                <div class="col-sm-11">
                                                                  <p style="border-bottom: 1px dotted">{{ appDetail.fair_name }}</p>
                                                                </div>
                                                                <div v-if="checkBn" class="col-sm-1"> {{ 'শীর্ষক' }}</div>
                                                                <div class="col-sm-12 text-justify">{{ checkBn ? 'স্থানীয়/আন্তর্জাতিক বাণিজ্য মেলা আয়োজন করতে আগ্রহী। এ বিষয়ে প্রয়োজনীয় কাগজপত্র ও তথ্যাদি আপনার সদয় বিবেচনা ও প্রয়োজনীয় পদক্ষেপ গ্রহণের জন্য উপস্থাপন করলাম।' : 'I have presented the necessary documents and information in this regard for your kind consideration and necessary action.'}}</div>
                                                            </b-row>
                                                            <br>
                                                            <br>
                                                            <b-row>
                                                              <div class="col-sm-1">
                                                                {{$t('globalTrans.date')}}
                                                              </div>
                                                                <div class="col-sm-5">
                                                                  <p style="border-bottom: 1px dotted">{{ appDetail.application_date | dateFormat }}</p>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <b-row>
                                                                        <div class="col-sm-6"></div>
                                                                        <div class="col-sm-6 text-center">
                                                                            <span>{{ checkBn ? 'বিনীত নিবেদক' : 'Regards-' }}</span><br>
                                                                            <img
                                                                            style="width: 106px;height: 30px;"
                                                                            class = "img-fluid"
                                                                            :src="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.signature_attachment" />
                                                                            <br>
                                                                            <span>{{ appDetail.applicant_name }}</span>
                                                                            <br>
                                                                            <span>{{ appDetail.designation }}</span>
                                                                        </div>
                                                                    </b-row>
                                                                </div>
                                                            </b-row>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                        </b-card>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                      </div>
                      <!-- documents -->
                      <b-row>
                          <b-col md="6">
                          <div>
                            <h6 class="mb-3 tag">{{ $t('dealer.important_document') }}</h6>
                          </div>
                          <div>
                            <ul>
                              <li v-if="appDetail?.payment_challan_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(1) }}. {{ $t('ditfDivisionAppManagement.payment_challan_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="appDetail.payment_challan_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.payment_challan_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="appDetail?.place_allotment_letter_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(2) }}. {{ $t('ditfDivisionAppManagement.place_allotment_letter_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="appDetail.place_allotment_letter_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.place_allotment_letter_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="appDetail?.trade_license_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(3) }}. {{ $t('ditfDivisionAppManagement.trade_license_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="appDetail.trade_license_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.trade_license_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="appDetail?.income_tax_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(4) }}. {{ $t('ditfDivisionAppManagement.income_tax_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="appDetail.income_tax_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.income_tax_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="appDetail?.vat_certificate_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(5) }}. {{ $t('ditfDivisionAppManagement.vat_certificate_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="appDetail.vat_certificate_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.vat_certificate_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="appDetail?.to_license_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(6) }}. {{ $t('ditfDivisionAppManagement.to_license_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="appDetail.to_license_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.to_license_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="appDetail?.certificate_of_incorporation_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(7) }}. {{ $t('ditfDivisionAppManagement.certificate_of_incorporation_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="appDetail.certificate_of_incorporation_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.certificate_of_incorporation_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="appDetail?.noc_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(8) }}. {{ $t('ditfDivisionAppManagement.noc_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="appDetail.noc_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + appDetail.noc_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col md="12" class="text-right">
                    <b-button variant="primary " class="btn-sm" @click="back()">{{ $t('globalTrans.back') }}</b-button>
                  </b-col>
                </b-row>
              </div>
            </b-overlay>
      </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { appsShowApi, appPdfApi } from '../../../api/routes'
import Loading from './loading/Details.vue'
export default {
  name: 'Details',
  props: ['id'],
  components: {
    Loading
  },
  data () {
    return {
      loading: false,
      forwarLoad: false,
      internationalTradeFairServiceBaseUrl: internationalTradeFairServiceBaseUrl,
      appDetail: {},
      forwardsShow: false,
      recommendationShow: false,
      users: []
    }
  },
  created () {
    this.appDetail = this.getAppDetail()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    appId () {
      return this.id
    },
    stepList () {
      const setp = [
        { value: 1, text: this.$t('globalTrans.pending') },
        { value: 2, text: this.$t('globalTrans.approved') },
        { value: 3, text: this.$t('ditfDivisionAppManagement.rejected') }
      ]
      if (this.appDetail.status === 1 || this.appDetail.status === 2) {
        return setp.filter(item => item.value !== 3)
      } else if (this.appDetail.status === 3) {
        return setp.filter(item => item.value !== 2)
      }
      return setp
    },
    checkBn () {
      return this.$i18n.locale === 'bn' ? 1 : 0
    }
  },
  methods: {
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    back () {
      this.$bvModal.hide('modal-4')
    },
    async getAppDetail () {
        this.loading = true
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, appsShowApi + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
        }
        this.loading = false
    },
    getOrgTypeName (id) {
      const data = this.orgType.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    getYesNoName (id) {
      const data = this.yesNoList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    getWorkerTypeName (id) {
      const data = this.workerType.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return data.text
      } else {
        return ''
      }
    },
    async pdfExport () {
      this.loader = true
      const params = Object.assign({}, { local: this.$i18n.locale, org_id: 2, request_type: 'pdf' })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, appPdfApi + '/' + this.appId, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.loader = false
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getCircularName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.teaAwardCircularList.find(item => item.value === id)
      if (obj) {
        return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
      }
      return ''
    },
    getCategoryName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.teaAwardCategoryList.find(item => item.value === id)
      if (obj) {
        return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
      }
      return ''
    }
  }
}
</script>
<style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    }

    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
.tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
}
.tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}
</style>
